<template>
  <div class="row">
    <el-form label-position="top" :inline="true">
      <el-form-item label="Quantity">
        <el-input-number
          v-model="quantity"
          :min="1"
          controls-position="right"
          @change="handleQuantity"
      /></el-form-item>
      <el-form-item label="Server" class="select-form-item"
        ><el-select v-model="selectedServer" @change="handleSelection">
          <el-option
            v-for="server in servers"
            :key="server.id"
            :label="server.name.toUpperCase()"
            :value="server.id"
          >
          </el-option></el-select
      ></el-form-item>
      <el-form-item>
        <el-button type="text" @click="configure" :disabled="!selectedServer"
          >Configure</el-button
        >
        <p
          class="btn-right inline-link"
          v-if="isConfigured"
          @click="setServerConfig"
        >
          Save configuration
        </p>
      </el-form-item>
      <el-form-item>
        <span class="remove-icon" @click="removeItem"
          ><i class="bi bi-x"></i
        ></span>
      </el-form-item>
    </el-form>
  </div>
  <el-dialog v-model="serverModal" :destroy-on-close="true" custom-class="server-modal">
    <saved-config @confSelected="getSelectedConf" />
    <template #title><h3>Configure the server</h3> </template>
    <template v-if="selectedServer">
      <!-- <p class="text-bold mb-4" :class="{ 'text-red': showError }">
        The server must at least have CPU, RAM and storage configured.
      </p> -->
      <div class="card modal-card" v-for="spec in serverConfig" :key="spec.key">
        <server-config
          :spec="spec"
          :maxSlots="maxSlots"
          :soc="soc"
          :config="config"
          :filters="filters"
          @setConfig="setConfig"
          @setFilter="setFilter"
          :serverId="selectedServer"
        />
      </div>
    </template>
    <template #footer>
      <el-button type="primary" @click="saveConfig">Next</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="energyModal">
    <template #title
      ><h3>
        Target Load and Intended Countries of Usage over Lifetime
      </h3></template
    >
    <energy-config @saveEnergy="saveEnergy" @goBack="goBack" :config="server" />
  </el-dialog>
</template>

<script>
import SavedConfig from '@/components/compare/SavedConfig';
import Feedback from '@/utils/services/Feedback';
import serverConfig from '@/utils/data/serverConfig';
import ServerConfig from '@/components/configure/ServerConfig';
import EnergyConfig from '@/components/configure/EnergyConfig';
import { ElMessageBox } from 'element-plus';

export default {
  name: 'ServerItem',
  props: ['index', 'item'],
  emits: ['saveServer', 'removeItem', 'quantity', 'isConfigured'],
  components: {
    ServerConfig,
    EnergyConfig,
    SavedConfig,
  },
  data() {
    return {
      serverConfig,
      servers: [],
      selectedServer: null,
      quantity: 1,
      serverModal: false,
      energyModal: false,
      server: {
        id: null,
        target_load: {},
        location: {},
      },
      maxSlots: {},
      filters: {},
      soc: false,
      config: {},
      showError: false,
      isConfigured: false,
      showConfig: true,
    };
  },
  async created() {
    const servers = await this.$store.dispatch('getServerList');
    let serverList = [];
    for (let i in servers) {
      serverList.push({
        name: `${servers[i].brand} ${servers[i].name}`,
        id: servers[i].product_id,
      });
    }
    this.servers = serverList;

    const isEmpty = Object.keys(this.item.server).length == 0;

    if (!isEmpty) {
      this.selectedServer = this.item.server.id;
      this.server = this.item.server;
      this.config = this.item.config;
    }
  },
  methods: {
    goBack() {
      this.energyModal = false;
      this.serverModal = true;
    },
    async configure() {
      this.$emit('isConfigured');
      const server = await this.$store.dispatch(
        'getServerById',
        this.selectedServer
      );

      this.getDefaultConfig(server);
      this.serverModal = true;
    },
    removeItem() {
      this.$emit('removeItem', this.index);
    },
    saveConfig() {
      this.serverModal = false;
      this.energyModal = true;
      this.showError = false;
    },
    setConfig(key, config) {
      this.config[key] = config;
    },
    setFilter(key, value) {
      this.filters[key] = value;
    },
    handleSelection() {
      this.server.id = this.selectedServer;
    },
    handleQuantity() {
      this.$emit('quantity', this.quantity, this.index);
    },
    async saveEnergy(target_load, location) {
      this.server.target_load = target_load;
      this.server.location = location;

      const serverData = {
        server: this.server,
        ...this.config,
      };

      const results = await this.$store.dispatch('getCompareData', serverData);

      const rack = {
        server: {
          ...this.server,
        },
        quantity: this.quantity,
        config: this.config,
        results: results,
      };
      this.$emit('saveServer', rack, this.index);
      this.isConfigured = true;
      this.energyModal = false;
    },
    validateServer() {
      let isValid = true;
      const keys = Object.keys(this.config);
      if (
        !keys.includes('cpu') ||
        !keys.includes('ram') ||
        !keys.includes('storage')
      ) {
        isValid = false;
      }
      return isValid;
    },
    getDefaultConfig(server) {
      this.maxSlots = {};
      this.maxSlots = {
        cpu: server.number_of_cpu_slot,
        ram: server.number_of_ram_slot,
        expansion: server.number_of_expansion_slot,
        fan: server.number_of_fan_slot,
        psu: server.number_of_psu_slot,
        storage: {
          lff: server.storage_form_factor.lff,
          sff: server.storage_form_factor.sff,
        }
      };

      this.filters = {};
      this.filters = {
        cpu: server.cpu_socket,
        ram: server.ram_socket,
        storage: server.storage_form_factor,
      };

      this.soc = null;
      this.soc = server.soc;

      this.config = {};
      if (server.default_spec) {
        Object.keys(server.default_spec).forEach((key) => {
          this.config[key] = server.default_spec[key];
        });
      }
    },
    setServerConfig() {
      ElMessageBox.prompt(
        'Please choose a name for your configuration',
        'Save configuration',
        {
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel',
        }
      ).then(({ value }) => {
        this.saveServerConfig(value);
      });
    },
    async saveServerConfig(label) {
      const config = {
        conf_label: label,
        conf: {
          server: this.server,
          ...this.config,
        },
      };

      const status = await this.$store.dispatch('saveConfig', config);

      if (status === 200)
        Feedback.showSuccess('Your server configuration has been saved');
      else Feedback.showError('There was an error saving your configuration.');
    },
    getSelectedConf(data) {
      this.showConfig = false;
      this.server = data.server;
      this.quantity = data.quantity;
      this.config = data.config;
      this.showConfig = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form--inline {
  .el-form-item {
    .el-select {
      min-width: 100% !important;
    }
  }
  .select-form-item {
    min-width: 50% !important;
  }
  button {
    margin-top: 2rem;
    margin-left: 2rem;
  }
  .remove-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 30px;
      margin-top: 2rem;
      cursor: pointer;
    }
  }
}

@include bp-down(lg) {
  .el-form {
    display: flex;
    justify-content: center;
    .select-form-item {
      min-width: auto !important;
    }
  }
}

.modal-card {
  height: fit-content;
}

.server-modal {
  .el-dialog__body {
    padding-top: 0 !important;
  }
}
</style>
