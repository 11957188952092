<template>
  <div class="container">
    <div class="row">
      <div class="col-12 header-col mb-2">
        <h1 class="title-w1">Compare</h1>
        <app-info title="Compare">
          <p>
            The CDCC Compare tool uses
            <span class="text-bold"
              >Life Cycle Sustainability Assessment (LCSA)</span
            >
            which encompasses the
            <span class="text-bold"
              >environmental, economic, and social impacts and criticality of
              materials</span
            >, of the equipment's entire life cycle from raw materials
            extraction to end-of-life stages. The environmental impact is
            calculated using
            <a href="https://cedaci-compass.weloop.org/glossary" target="_blank" class="text-underlined"
              >Life Cycle Assessment (LCA)</a
            >, economic - using
            <a href="https://cedaci-compass.weloop.org/glossary" target="_blank" class="text-underlined"
              >Life Cycle Costing (LCC)</a
            >, social - using
            <a href="https://cedaci-compass.weloop.org/glossary" target="_blank" class="text-underlined"
              >Social Life Cycle Assessment (S-LCA)</a
            >
            and criticality represents the assessment of metals' criticality in
            DC equipment.
          </p>
          <p>
            To find out which equipment is most circular,
            <span class="text-bold">select and configure</span> two data centres,
            compare the impacts and
            <span class="text-bold">download</span> the full PDF report.
          </p>
        </app-info>
      </div>
    </div>
    <compare-tool />
  </div>
</template>

<script>
import CompareTool from '@/components/compare/CompareTool';
export default {
  name: 'Compare',
  components: {
    CompareTool,
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 10rem;
}
</style>
